HTMLCollection.prototype.formDatePicker = NodeList.prototype.formDatePicker = function(){
    for(i=0; i< this.length; i++) this[i].formDatePicker();
}

Element.prototype.formDatePicker = function ($options = {}){

    const days = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'];
    const daysComplet = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
    const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'decembre'];

    var now = new Date(Date.now());
    const $now = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0 , 0 ,0);
    const $field = this;
    const $container = createElement('div').addClass('calendar-container').addClass('d-none');
    $('body').append($container);

    $field.addEvent('click', function(e){e.stopPropagation(); e.preventDefault()});
    $container.addEvent('click', function(e){e.stopPropagation(); e.preventDefault()});
    $field.addEvent('focus', function(e){
        this.build(new $date($field.value));
    });
    $field.addEvent('change', function(e){
        $container.addClass('d-none');
    });

    window.addEventListener('click', function(){ $container.addClass('d-none');})
    window.addEventListener('resize', function(){
        var pos = $field.offset();
        $container.setStyle('top', (pos.top + pos.height) + 'px').setStyle('left', pos.left + 'px');
    })

    //---------------------------------------------------------------------------

    this.buildHeader = function(_date){
        $container.find('.calendar-header').destroy();
        var header = $container.createElementInside('div').addClass('calendar-header');
        var date = header.createElementInside('span').addClass('calendar-date');
        var nav = header.createElementInside('span').addClass('calendar-nav');
        var nav1 = nav.createElementInside('span').addClass('calendar-nav');
        var nav2 = nav.createElementInside('span').addClass('calendar-nav');

        var previousMonth = nav1.createElementInside('span').addClass('calendar-btn').addClass('calendar-previous');
        var month = nav1.createElementInside('span').addClass('calendar-month');
        var nextMonth = nav1.createElementInside('span').addClass('calendar-btn').addClass('calendar-next');

        var previousYear = nav2.createElementInside('span').addClass('calendar-btn').addClass('calendar-previous');
        var year = nav2.createElementInside('span').addClass('calendar-year');
        var nextYear = nav2.createElementInside('span').addClass('calendar-btn').addClass('calendar-next');

        date.innerText = daysComplet[_date.dayWeek()] + ' ' + _date.day() + ' ' + months[parseInt(_date.month()) - 1] +' ' + _date.year();
        month.innerText = months[parseInt(_date.month()) - 1];
        year.innerText = _date.year();

        previousMonth.addEvent('click', function(e){
            e.stopPropagation();
            $field.build(new $date(_date.previousMonth()));
        });
        nextMonth.addEvent('click', function(e){
            e.stopPropagation();
            $field.build(new $date(_date.nextMonth()));
        });
        previousYear.addEvent('click', function(e){
            e.stopPropagation();
            $field.build(new $date(_date.previousYear()));
        });
        nextYear.addEvent('click', function(e){
            e.stopPropagation();
            $field.build(new $date(_date.nextYear()));
        });
    }

    //---------------------------------------------------------------------------

    this.build = function(_date){
        var pos = $field.offset();
        $container.setStyle('top', (pos.top + pos.height) + 'px').setStyle('left', pos.left + 'px');
        this.buildHeader(_date);
        this.buildCalendar(_date);
    }

    //---------------------------------------------------------------------------

    this.refresh = function(_date){
        var date = new $date(_date);
        $field.value = date.formDateValue();
        $field.buildHeader(date);
        $field.buildCalendar(date);
    }

    this.buildCalendar = function(date){
        $container.removeClass('d-none');
        var month = date.monthArray();

        $container.find('.table-calendar').destroy();
        var calendar = $container.createElementInside('table').addClass('table-calendar');
        var header = calendar.createElementInside('thead').createElementInside('tr');
        var tbody = calendar.createElementInside('tbody');
        for(let i = 0; i < days.length; i++) header.createElementInside('th').innerText = days[i];
        var tr = tbody.createElementInside('tr');
        var formDateValue = new $date($field.value);
        month.forEach(function(item, index){
            if((index) % 7 == 0){
                tr = tbody.createElementInside('tr');
            }
            if(item){
                var td = tr.createElementInside('td').addClass('day');
                td.innerText = item.index;
                if(formDateValue.same(item.date)) td.addClass('selected-day');
                if(new $date($now).same(item.date)) td.addClass('today');

                td.addEvent('click', function(e){ e.stopPropagation(); $field.refresh(item.date); });
            }else{
                tr.createElementInside('td').addClass('day-off');
            }

        });
    }

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

window.$date = function(_dateTime = null){

    $this = this;

    const parse = function(dateTime){
        if (dateTime == '') return new Date(Date.now());
        if (typeof dateTime == 'string'){
            var d = dateTime.split('-');
            return new Date(d[0], parseInt(d[1]) - 1 , parseInt(d[2]), 0,0,0 );
        }
        return new Date(_dateTime);
    }

    //------------------------------------------------------------------------------

    const date = parse(_dateTime);

    //------------------------------------------------------------------------------

    this.same = function(d) {
        return d.getTime() === date.getTime();
    }

    //-------------------------------------------------------------------------------

    this.previousMonth = function(){return new Date(date.setMonth(date.getMonth() - 1));}
    this.nextMonth = function(){return new Date(date.setMonth(date.getMonth() + 1));}
    this.previousYear = function(){return new Date(date.setFullYear(date.getFullYear() - 1));}
    this.nextYear = function(){return new Date(date.setFullYear(date.getFullYear() + 1));}

    // methodes de formatage de date

    const dayOfTheWeek = function(){return (date.getDay() == 0) ? 6 : date.getDay() - 1;}
    this.dayWeek = function(){return dayOfTheWeek();}
    this.day = function(){return (date.getDate() < 10) ? '0' + date.getDate().toString() : date.getDate();}
    this.month = function(){return (date.getMonth() < 9) ? '0' + (date.getMonth() + 1).toString() : date.getMonth() + 1;}
    this.year = function(){return date.getFullYear();}

    this.formDate = function(){
        return $this.day() + '/' + $this.month() + '/' + date.getFullYear();
    }

    this.formDateValue = function(){
        return date.getFullYear() + '-' + $this.month() + '-' + $this.day();
    }

    this.monthArray = function(){
        var Y = date.getFullYear();
        var m = date.getMonth();
        var firstDay = new Date(Y, m, 1);
        var lastDay = new Date(Y, m + 1,0);
        var dayWeek = (firstDay.getDay() == 0) ? 6 : firstDay.getDay() - 1;
        var tab = [];
        for (let u = 0; u < dayWeek; u++) tab.push(null);
        var i = 1;
        while(i <= lastDay.getDate()){
            var data = {'index' : i, 'date' : new Date(Y, m, i, 0,0,0)};
            tab.push(data);
            i++;
        }

        return tab;
    }


}


